import React from 'react'
import { Modal } from 'react-bootstrap'

export const FullSacleTableModal = ({
    openModal,
    handleTooltipClick
}) => (
    <Modal className="scale-table" centered show={openModal} onHide={handleTooltipClick}>
        <Modal.Body className="tooltip-modal-body">
            <button className="close-btn" type="button" onClick={handleTooltipClick}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="12" fill="#05316F"/>
                    <path d="M16.7761 14.4627C16.9254 14.6119 17 14.8358 17 15.0597C17 15.2836 16.9254 15.5075 16.7761 15.6567L15.6567 16.7761C15.5074 16.9254 15.2836 17 15.0597 17C14.8358 17 14.6119 16.9254 14.4627 16.7761L12 14.3134L9.53731 16.7761C9.38806 16.9254 9.16416 17 8.94028 17C8.7164 17 8.49253 16.9254 8.34328 16.7761L7.22387 15.6567C7.07462 15.5075 7 15.2836 7 15.0597C7 14.8358 7.07462 14.6119 7.22387 14.4627L9.68655 12L7.22387 9.53732C7.07462 9.38806 7 9.16418 7 8.9403C7 8.71642 7.07462 8.49253 7.22387 8.34328L8.34328 7.22388C8.49253 7.07462 8.7164 7 8.94028 7C9.16416 7 9.38806 7.07462 9.53731 7.22388L12 9.68657L14.4627 7.22388C14.6119 7.07462 14.8358 7 15.0597 7C15.2836 7 15.5074 7.07462 15.6567 7.22388L16.7761 8.34328C16.9254 8.49253 17 8.71642 17 8.9403C17 9.16418 16.9254 9.38806 16.7761 9.53732L14.3134 12L16.7761 14.4627Z" fill="white"/>
                </svg>
            </button>
            <div>

                <h3 className="full-scale-heading mb-4">Full WHO 11-point COVID-19 patient status scale</h3>
                <table className="who-full-scale-table">
                    <thead>
                        <tr>
                            <th>Score</th>
                            <th>Descriptor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-yellow">
                            <td className="text-center">0</td>
                            <td>Uninfected; no viral RNA detected</td>
                        </tr>
                        <tr className="bg-yellow">
                            <td className="text-center">1</td>
                            <td>Asymptomatic; viral RNA detected</td>
                        </tr>
                        <tr className="bg-yellow">
                            <td className="text-center">2</td>
                            <td>Symptomatic: Independent</td>
                        </tr>
                        <tr className="bg-yellow">
                            <td className="text-center">3</td>
                            <td>Symptomatic: assistance needed</td>
                        </tr>
                        <tr className="bg-orange">
                            <td className="text-center">4</td>
                            <td>Hospitalized; no oxygen therapy</td>
                        </tr>
                        <tr className="bg-orange">
                            <td className="text-center">5</td>
                            <td>Hospitalized; oxygen by mask or nasal prongs</td>
                        </tr>
                        <tr className="bg-orange">
                            <td className="text-center">6</td>
                            <td>Hospitalized; oxygen by NIV or High flow</td>
                        </tr>
                        <tr className="bg-purple">
                            <td className="text-center">7</td>
                            <td>{`Intubation & Mechanical ventilation, pO2/FIO2>/=150 or SpO2/FIO2>/=200`}</td>
                        </tr>
                        <tr className="bg-purple">
                            <td className="text-center">8</td>
                            <td>{`Mechanical ventilation pO2/FIO2<150 (SpO2/FIO2<200) or vasopressors`}</td>
                        </tr>
                        <tr className="bg-purple">
                            <td className="text-center">9</td>
                            <td>{`Mechanical ventilation pO2/FIO2<150 and vasopressors, dialysis or ECMO`}</td>
                        </tr>
                        <tr className="bg-black">
                            <td className="text-center">10</td>
                            <td>Death</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Modal.Body>
    </Modal>
)